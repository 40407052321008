.not-found {
  padding: 300px 40px;
  text-align: center;
}

@media (max-width:800px) {
  .not-found {
    padding: 100px 40px;
  }
}
