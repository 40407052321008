.recharts-line {
    g {
      text:nth-child(even), circle:nth-child(even) {
        display:none;
      }
    }
}

.burst{
  position: absolute;
  top:0;
  bottom:0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;

  ul,
  li {
      position: absolute;
      list-style-type: none;
      margin: 0;
      padding: 0;
  }

  ul::before,
  ul::after,
  li::before,
  li::after {
      content: "";
      position: absolute;
  }

  ul.topLeft {
      width: 40%;
      height: 40%;
      top: 30%;
      left: 40%;
      border-radius: 50%;
      transform: translate(-50%, -50%);

      li {
          width: 6px;
          height: 40%;
          top: 0;
          left: 50%;
          border-radius: 30px;
          transform-origin: 50% 100%;
      }
  }

  ul.middleRight {
      width: 50%;
      height: 50%;
      top: 40%;
      left: 70%;
      border-radius: 50%;
      transform: translate(-50%, -50%);

      li {
          width: 6px;
          height: 50%;
          top: 0;
          left: 50%;
          border-radius: 30px;
          transform-origin: 50% 100%;
      }
  }

  ul.bottomLeft {
      width: 40%;
      height: 40%;
      top: 60%;
      left: 30%;
      border-radius: 50%;
      transform: translate(-50%, -50%);

      li {
          width: 6px;
          height: 40%;
          top: 0;
          left: 50%;
          border-radius: 30px;
          transform-origin: 50% 100%;
      }
  }

  li::before,
  li::after {
      width: 100%;
      height: 50%;
      top: 100%;
      background: #fff;
      border-radius: 30px;
  }

  li:nth-child(1) {
      transform: translateX(-50%) rotate(45deg);
  }

  li:nth-child(1)::before,
  li:nth-child(1)::after {
      animation: burst 1s ease-in-out infinite;
      animation-fill-mode: backwards;
  }

  li:nth-child(1)::before {
      animation-delay: 0.05s;
  }

  li:nth-child(1)::after {
      animation-delay: 0.1s;
  }

  li:nth-child(2) {
      transform: translateX(-50%) rotate(90deg);
  }

  li:nth-child(2)::before,
  li:nth-child(2)::after {
      animation: burst 1s ease-in-out infinite;
      animation-fill-mode: backwards;
  }

  li:nth-child(2)::before {
      animation-delay: 0.05s;
  }

  li:nth-child(2)::after {
      animation-delay: 0.1s;
  }

  li:nth-child(3) {
      transform: translateX(-50%) rotate(135deg);
  }

  li:nth-child(3)::before,
  li:nth-child(3)::after {
      animation: burst 1s ease-in-out infinite;
      animation-fill-mode: backwards;
  }

  li:nth-child(3)::before {
      animation-delay: 0.05s;
  }

  li:nth-child(3)::after {
      animation-delay: 0.1s;
  }

  li:nth-child(4) {
      transform: translateX(-50%) rotate(180deg);
  }

  li:nth-child(4)::before,
  li:nth-child(4)::after {
      animation: burst 1s ease-in-out infinite;
      animation-fill-mode: backwards;
  }

  li:nth-child(4)::before {
      animation-delay: 0.05s;
  }

  li:nth-child(4)::after {
      animation-delay: 0.1s;
  }

  li:nth-child(5) {
      transform: translateX(-50%) rotate(225deg);
  }

  li:nth-child(5)::before,
  li:nth-child(5)::after {
      animation: burst 1s ease-in-out infinite;
      animation-fill-mode: backwards;
  }

  li:nth-child(5)::before {
      animation-delay: 0.05s;
  }

  li:nth-child(5)::after {
      animation-delay: 0.1s;
  }

  li:nth-child(6) {
      transform: translateX(-50%) rotate(270deg);
  }

  li:nth-child(6)::before,
  li:nth-child(6)::after {
      animation: burst 1s ease-in-out infinite;
      animation-fill-mode: backwards;
  }

  li:nth-child(6)::before {
      animation-delay: 0.05s;
  }

  li:nth-child(6)::after {
      animation-delay: 0.1s;
  }

  li:nth-child(7) {
      transform: translateX(-50%) rotate(315deg);
  }

  li:nth-child(7)::before,
  li:nth-child(7)::after {
      animation: burst 1s ease-in-out infinite;
      animation-fill-mode: backwards;
  }

  li:nth-child(7)::before {
      animation-delay: 0.05s;
  }

  li:nth-child(7)::after {
      animation-delay: 0.1s;
  }

  li:nth-child(8) {
      transform: translateX(-50%) rotate(360deg);
  }

  li:nth-child(8)::before,
  li:nth-child(8)::after {
      animation: burst 1s ease-in-out infinite;
      animation-fill-mode: backwards;
  }

  li:nth-child(8)::before {
      animation-delay: 0.05s;
  }

  li:nth-child(8)::after {
      animation-delay: 0.1s;
  }

  li:nth-child(9) {
      transform: translateX(-50%) rotate(405deg);
  }

  li:nth-child(9)::before,
  li:nth-child(9)::after {
      animation: burst 1s ease-in-out infinite;
      animation-fill-mode: backwards;
  }

  li:nth-child(9)::before {
      animation-delay: 0.05s;
  }

  li:nth-child(9)::after {
      animation-delay: 0.1s;
  }

  @keyframes burst {

      0%,
      100% {
          top: 100%;
          opacity: 0;
          background-color: #fff;
      }

      20% {
          opacity: 0;
      }

      50% {
          opacity: 0.5;
      }

      65% {
          opacity: 1;
      }

      99% {
          top: -50%;
          opacity: 0;
          height: 0;
          background-color: #fa7918;
      }
  }
}