.footer {
  background-color: #777;
  padding:40px;
  color:#fff;

  @media all and (max-width: 900px) {
      text-align: center;

      .footer-links-inner {
          align-items: center !important;
          justify-content: center !important;
      }

      ul {
          align-items: center;
          justify-content: center;
      }
  }

  .footer-links {
    max-width: 1275px;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 24px;
    padding: var(--mdc-layout-grid-margin-desktop,24px);

    .footer-links-inner {
      margin-left: auto;
      margin-right: auto;
      max-width: 960px;
      max-width: var(--material-footer-max-width,960px);
      width: 100%;

      .list {
        display: 'flex';
        flex-direction: 'row';
        padding: 0;
      }

      a {
        margin-bottom:5px;
      }

      h3 {
        font-weight: bold;
        margin-bottom:30px;
        font-size:16px;
      }
    }
  }

  .copyright {
    text-align: center;
    font-size:14px;

    img {
      max-width: 256px;
      height:auto;
    }
  }
}
