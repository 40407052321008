$mobileWidth: 900px;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #777;

  .wrapper {
    max-width: 1275px;
    margin: auto;
  }

  .wrapper.wrapper-small {
    max-width: 900px;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
  }

  .MuiButton-root {
    text-transform: none !important;
  }

  .MuiButton-containedPrimary, .MuiBadge-colorPrimary {
    color:#FFF !important;
  }

  .MuiPickersPopper-root {
    z-index: 9999 !important;
  }
}
