.navigation{
  .nav-desktop {
    .list-item {
      display: inline-block;
      position: relative;
      overflow: visible;

      .subpages {
        background-color:#fff;
        position:absolute;
        top:100%;
        right:0;
        box-shadow:0px 0px 10px #777;
        margin:0;
        padding:0;
        min-width:239px;
        opacity:0;
        margin-top:10px;
        z-index:0;
        height:0;
        overflow:hidden;
        transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -webkit-transition: all 0.5s ease-in-out;
        
        a {
          margin-left:0 !important;
          border-bottom:1px solid #E5E5E5;
        }
      }

      &:hover .subpages {
        opacity:1;
        margin-top:0px;
        z-index:9;
        height:auto;
      }

      a {
        margin-left:20px !important;
        font-size:16px !important;
        font-weight: bold;
      }
    }
  }
}
