.AppBar {
  box-shadow: none !important;
  border-bottom:1px solid rgba(0, 0, 0, 0.12) !important;

  .logoDiv {
    height: 40px;

    .logo {
      height: 40px;
      display: inline-block;
      background-size:0px;
      background-position: center;
      background-repeat: no-repeat;

      img {
        height: 40px;
        width: auto;
      }

      @media (max-width:1100px) {
        width: 40px;
        background-size:40px 40px;

        img {
          display: none;
        }
      }
    }
  }
}
