.recover {
  padding: 60px 40px 400px;

  .MuiStepIcon-text {
    fill:#fff !important;
  }
}

@media (max-width:800px) {
  .recover {
    padding: 60px 40px 150px;
  }
}
